import React, { useEffect } from "react";
import { GetServerSidePropsContext } from "next";
import { useRouter } from "next/router";
import {  useDispatch, useSelector } from "react-redux";
import Head from "next/head";

import { RootState } from "@/store";
import { userSaveSlice } from "@/store/userSave";
import CheckAuth from "@/components/common/CheckAuth";

//Page Props
import { PageProps } from "@/assets/pageprops/index";
import * as viewUtil from "@/util/viewUtil";

//URL パラメータの受け取り
export async function getServerSideProps(context:GetServerSidePropsContext) {
  return {
    props: {
      code: viewUtil.getQueryStringOne(context.query.code),
    },
  }
}

//Main
const Index = (props: PageProps): JSX.Element => {
  const router = useRouter();

  //Store, Dispatcher
  const dispatch = useDispatch();
  const userStore = useSelector((state: RootState) => state.user);
  const userSaveStore = useSelector((state: RootState) => state.userSave);

  //DEF componentDidMount
  const componentDidMount = ()=>{
      dispatch(userSaveSlice.actions.initOnDidMount());

      if (userSaveStore.url) {
        router.push(userSaveStore.url);
      }
      else {
        router.push('/mainpage');
      }
  
  };

  //DEF componentWillUnmount
  const componentWillUnmount = ()=>{
    dispatch(userSaveSlice.actions.resetOnWillUnmount());
  }

  useEffect(() => {
    //DO componentWillUnmount
    componentDidMount.bind(this)();
    return ()=>{
      //DO componentWillUnmount
      componentWillUnmount.bind(this)();
    }
  }, []); //Empty

  // useEffect(() => {
  //   console.log(userSaveStore.url);
  //   console.log(userStore);
  //   if (!userStore.isAuthed) {
  //     //認証前、URL共有、前回画面あり
  //     return;
  //   }
  //   if (userSaveStore.url) {
  //     router.push(userSaveStore.url);
  //   }
  //   else {
  //     router.push('/mainpage');
  //   }
  // }, [userStore.permissions, userStore.isAuthed]);

  const title = `-`;
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <CheckAuth>
      </CheckAuth>
    </>
  );
};

//Export
export default Index;

